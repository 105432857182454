import React, {useEffect} from 'react';
import { isAfter } from 'date-fns';

import LayoutPanel from 'components/layout/layoutPanel';
import { LinkModal } from 'kh-common-components';
import { modalContents } from 'constants/modal-content';

import compDysonImage from 'images/competition/flowmoji-dyson-win.svg';

function CompetitionDyson (props) {

    useEffect(()=> {
        const isNonPrd = process.env.GATSBY_ACTIVE_ENV !== 'prd';

        const dateStart = isNonPrd ? new Date(2022, 9, 14, 10) : new Date(2022, 9, 24); // oct 24
        const dateExpire = isNonPrd ? new Date(2023, 3, 18) : new Date(2023, 3, 18); // apr 18
        const currentDate = new Date();
    
        // returns true if currentDate is past dateStart AND not expiring
        // returns false if currentDate is past dateExpiring OR currentDate is not yet starting
        const triggerExpire = isAfter(currentDate, dateExpire);
        const triggerSwitch = isAfter(currentDate, dateStart);
    
        const dysonCompetitionPanel = document.getElementsByClassName('dyson-competition')[0];
    
        if (!triggerExpire && triggerSwitch) {
            dysonCompetitionPanel.classList.remove('d-none');
        } else {
            dysonCompetitionPanel.classList.add('d-none');
        }
    })

    return (
        <LayoutPanel background="mer-theme--light mer-bg--ui-light d-none dyson-competition"
                     padding="mer-pt-sm mer-pb-de"
                     border="mer-border--top mer-border--bottom"
                     id="competition">
            <div className="container">
                <div className="row mer-panel">
                    <div className="mer-panel__images col-5 col-sm-3 col-lg-2 mb-0">
                        <img src={compDysonImage} 
                             className="mer-panel__image"
                             alt="Win* a dyson flowmoji" />
                    </div>
                    <div className="content col-10 col-sm-6">
                        <p><strong>Move your gas with Kleenheat during the competition period to be in the running to win a Dyson V15 Detect vacuum worth over $1,300!*</strong></p>

                        <small>
                            <LinkModal {...props}
                                        modalContents={modalContents}
                                        useModal="true"
                                        title="Terms & conditions"
                                        // linkClass=""
                                        displayContent="*Terms and conditions apply"
                                        modalTitle="Terms & conditions"
                                        modalContentName='comp_dyson_terms'
                                        okayButtonLabel='Okay'
                                />
                        </small>
                    </div>
                </div>
            </div>
        </LayoutPanel>
    );
}

export default CompetitionDyson;