import React from 'react';
import PlacesAutocomplete from 'react-places-autocomplete';
import {isClientSideRendering} from '../../constants/global';
import { addressInputTouchEvents } from 'kh-common-components';
import Image from 'components/common/Image';

const googleLogoContainerStyles = {
  textAlign: 'center',
  paddingTop: '.1875em',
  borderTop: '1px solid #d9d9d9'
};

const googleLogoStyles = {
  width: '7.1875em'
};

class LocationSearchInput extends React.Component {
  constructor(props) {
      super(props);

      this.state = {
          address: this.props.initialValue
      };
      
      this.handleChange = this.handleChange.bind(this);
      this.handleSelect = this.handleSelect.bind(this);
      this.onBlur = this.onBlur.bind(this); 
  }

  //-- The following is use for setting search options to filter and prioritise the suggestion list.
  searchOptions = {
      location: isClientSideRendering ? new window.google.maps.LatLng(-32, 116) : null, //-- This is Perth latitude and longitude.
      radius: 2000,
      types:['address'],
      componentRestrictions: {country: 'au'}
  }
  
  renderFunc = ({ getInputProps, suggestions, getSuggestionItemProps }) => (
      <React.Fragment>

          {/* The following input is the search text box */}
          <div className="mdc-text-field mdc-text-field--outlined mdc-text-field--no-label mer-z-index--base">
              <input 
                   {...getInputProps({
                      className: 'location-search-input mdc-text-field__input'                
                  })}
                  id={this.props.id !== undefined ? this.props.id : this.props.name}
                  placeholder={this.props.placeholder}
                  name={this.props.name}
                  onBlur={this.onBlur}
                  onFocus={this.onFocus}
                  value={this.state.address}
                  maxLength={240}
                  required
              />
              <div className="mdc-notched-outline">
                  <div className="mdc-notched-outline__leading"></div>
                  <div className="mdc-notched-outline__trailing"></div>
              </div>

              {/* The following will display the autocomplete dropdown. */}
              {suggestions.length > 0 && (
                  <div className="autocomplete-dropdown-container pac-container pac-container--popout">
                      {/* remove loading - #23704
                       * loading && 
                          <div className="mer-loading-spinner">Loading...</div>
                      */}
                      {suggestions.map((suggestion, index) => {

                          const className = suggestion.active
                              ? 'pac-item pac-item-query suggestion-item--active pac-item-selected'
                              : 'pac-item suggestion-item';
                          let description = suggestion.description;
                          if (suggestion.matchedSubstrings !== undefined &&
                              suggestion.matchedSubstrings !== null &&
                              suggestion.matchedSubstrings.length > 0) {
                              let replacementNum = 0;
                              for (var key in suggestion.matchedSubstrings) {
                                  let match = suggestion.matchedSubstrings[key];
                                  let offset = match.offset + 2 * replacementNum;
                                  let length = match.length;
                                  replacementNum = replacementNum + 1;
                                  description = description.slice(0, offset) + '<' + description.slice(offset);
                                  description = description.slice(0, offset + length + 1) +
                                      '!' +
                                      description.slice(offset + length + 1);
                              }
                              if(description.split(',').length>1){
                                 description = description.split(',')[0] + description.split(',')[1]; // remove country
                              }
                              description = description.replace(/</g, '<strong class="pac-matched">').replace(/!/g, '</strong>').replace(/,/,'');
                          }


                          return (
                              <div {...getSuggestionItemProps(suggestion, { className })} key={index}>
                                  {/* TODO might have to look at package like html-react-parser instead of using dangerourslySetInnerHTML */}
                                  <span dangerouslySetInnerHTML={{ __html: description }}></span>
                              </div>
                          );
                      })}
                      <div style={googleLogoContainerStyles}>
                          <Image style={googleLogoStyles}
                                 src="/powered-by-google-on-white.png"
                                 alt="Powered by Google"
                          />
                      </div>
                  </div>
              )}

              {this.props.caption && <span className="mer-form-field__descriptor">{this.props.caption}</span>}
          </div>
      </React.Fragment>
  );

  handleChange = address => {    
      this.setState({ address });      
      if (this.props.onChange !== undefined) {
        this.props.onChange(address);
      }
  };
  
  onBlur(e) {
      const target = e.target;
      const targetParent = target.parentElement;
      targetParent.classList.remove('mdc-text-field--focused');

      if (targetParent.querySelector('.pac-container')) {
          targetParent.querySelector('.pac-container').classList.add('d-none');
      }
  }

  onFocus(e) {

      const target = e.target;
      const targetParent = target.parentElement;
      targetParent.classList.add('mdc-text-field--focused');      

      if (targetParent.querySelector('.pac-container')) {
          targetParent.querySelector('.pac-container').classList.remove('d-none');
      }

      // touch event
      const targetClosestField = target.closest('.mer-form__field');
      addressInputTouchEvents(targetClosestField);
  }

  handleSelect = (address, placeId) => {  
    if(address.split(',').length>1){
      address = address.split(',')[0] + address.split(',')[1]; // remove country
    }
      this.setState({ address });

    if (this.props.onSelect !== undefined) {
        this.props.onSelect(address);
    }
  }

  handleError = (status, clearSuggestions) => {
      console.log('Google Maps API returned error with status: ', status);
      clearSuggestions();
  }

  render() {
      return (
          <PlacesAutocomplete 
              value={this.state.address}
              onChange={this.handleChange}
              onSelect={this.handleSelect}
              searchOptions={this.searchOptions}
              highlightFirstSuggestion
              shouldFetchSuggestions={this.state.address.length > 0}
              onError={this.handleError}
              clearItemsOnError
          >
              {this.renderFunc}
          </PlacesAutocomplete>
      );
  }
}

/* EXPORT SECTION */
export default LocationSearchInput;