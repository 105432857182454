import React, { useState } from 'react';
import PropTypes from "prop-types";
import LocationSearchInput from 'components/common/LocationSearchInput';
import { navigate } from 'gatsby';
import { GtmHelper, getCookieValue, deleteCookie, setCookie, Svg } from 'kh-common-components';

const AddressSearchInput = ({searchButtonText, initialAddress, label, padding, onAddressChange, showLabel, isRequired, useTrigger=false, onSelection=null, labelClass='', searchIcon, isSsfRedirect=false}) => {
  const [address, setAddress] = useState(initialAddress);
  const isFlex = searchButtonText ? 'mer-form__field-action--flex' : '';
  const isLabelVisible = !showLabel ? 'mer-display--visual-hidden' : '';
  const isRequiredField = isRequired ? 'mer-form-label-required' : '';
  const ctaSvgIcon = searchIcon ? searchIcon : 'chevron-right';
    
  function handleClick(e) {
    e.preventDefault();    
    // console.log('address: ' + address); 

    if(!useTrigger){

      if (isSsfRedirect) {
        GtmHelper.sendFormComplete('Address search');
        ssfRedirect(address);
      } else {
        navigate(`/service-availability`, { state: { address : address } });
      }

    } else { 
      if(onSelection!==undefined && onSelection!==null){
        onSelection(address,false);
      }
    }
  }

  function handleChange(address) {    
    setAddress(address);
    if (onAddressChange !== undefined) {
      onAddressChange(address);
    }
  }

  function handleSelect(address) {          
    setAddress(address);            
    GtmHelper.sendAddressSearchAutoComplete();


    if(!useTrigger){

      if (isSsfRedirect) {
        ssfRedirect(address);
      } else {
        navigate(`/service-availability`, { state: { address : address, isAutoComplete : true } });    
      }

    } else{ 
      if(onSelection!==undefined && onSelection!==null){
        onSelection(address,false);
      }
    }
  }

  function ssfRedirect(address) {

    const cookieName = process.env.ADDRESS_COOKIE_NAME;
    const cookieValue = getCookieValue(cookieName);

    deleteCookie(cookieName);
    setCookie(cookieName, address, 1);

    window.location.href = '/sign-up';
  }

  return (
    
    <div className={`mer-form__field ${padding}`}>
        { label !== '' &&
          <label className={`mer-form__field-label ${isLabelVisible} ${isRequiredField} ${labelClass}`} htmlFor="addressSearch.address">
              {label}
          </label>
        }
        <div className={`mer-form__field-action ${isFlex} ${!showLabel ? 'mt-3' : ''}`}>

            <LocationSearchInput                         
              initialValue={address} 
              name="addressSearch.address" 
              placeholder="Get started with your address" 
              fieldLabel="Get started with your address" 
              onChange={handleChange}
              onSelect={handleSelect} />

            { searchButtonText !== undefined && 
                <button onClick={handleClick} className="mer-button mer-button--primary">
                  <span className="d-none d-sm-block mer-text--no-wrap">{searchButtonText}</span>
                  <Svg title={searchButtonText}
                        col="mer-svg-container--size-xs d-sm-none mer-fill--gs-white"
                        icon={ctaSvgIcon} />
                </button>             
            }         
        </div>
    </div>    
  )
}

AddressSearchInput.defaultProps = {      
  initialAddress: '',
  label: '',
  padding: '',
  searchIcon: '',
  isRequired: false
};

AddressSearchInput.propTypes = {
  searchButtonText: PropTypes.string,
  initialAddress: PropTypes.string,
  label: PropTypes.string,
  padding: PropTypes.string,
  searchIcon: PropTypes.string,
  isRequired: PropTypes.bool,
  onAddressChange: PropTypes.func
};


export default AddressSearchInput;