import React from 'react';
import LayoutPanel from 'components/layout/layoutPanel';

import Image from 'components/common/Image';

function NewHomeInfo (props) {

  return (
    <LayoutPanel background="mer-bg--gs-grey-lighter"
                  padding="mer-py-lg">
        <div className="container">
            <div className="row mer-panel">

                <div className="col-10 col-xl-5 col-md-6 col-sm-7 offset-sm-1 order-sm-2 content">
                    <h2>Building or connecting your new home to natural gas?</h2>
                    <p>Getting started with Kleenheat is easy! In a few minutes we can have your account set up and ready for your builder or gas fitter to install a natural gas connection.</p>
                    <div className="mer-panel__actions mer-button-group">
                        <a href="/sign-up" className="mer-button mer-button--primary">Get started</a>
                    </div>
                </div>

                <div className="col-5 col-lg-3 col-sm-4 order-sm-1 mer-panel__images">
                    <Image className="mer-panel__image"
                           src="/icons/flowmoji/flowmoji-newhome.svg"
                           alt="Flowmoji new home"
                    />
                </div>
            </div>
        </div>
    </LayoutPanel>
  )
}

export default NewHomeInfo;